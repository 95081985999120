<div class="navbar">
  <div class="navbar-content">
    <!-- left aligned menu -->
    <div class="navbar-left">
      <!-- logo -->
      <div class="navbar-logo">
        <a routerLink="/" title="Registre de preuve de covoiturage">
          <img src="assets/img/covoituragebetagouvfr.svg" alt="Registre de preuve de covoiturage" />
        </a>
      </div>

      <!-- Campagnes -->
      <div *ngIf="user && !isTerritoryDemo" class="navbar-menu-item campaign-menu-item" [routerLink]="'/campaign'"
        routerLinkActive="active">
        Campagnes
      </div>

      <!-- Trajets -->
      <div *ngIf="user && !isTerritoryDemo" class="navbar-menu-item trip-menu-item" [routerLink]="'/trip'"
        routerLinkActive="active">
        Trajets
      </div>

    </div>

    <!-- right aligned menu -->
    <div class="navbar-right" *ngIf="user">
      <div class="navbar-user">
        <mat-icon>{{ setUserIcon() }}</mat-icon>
        <div class="navbar-user-name">
          <div>{{ user.firstname + ' ' + user.lastname }}</div>
          <div class="navbar-user-meta">{{ userMeta }}</div>
        </div>
      </div>

      <button mat-button [extLink]="getDocURL()" matTooltip="Documentation" data-test="button-doc">
        <mat-icon>info</mat-icon>
      </button>
      <button mat-button routerLink="/admin" matTooltip="Administration" data-test="button-admin">
        <mat-icon>settings</mat-icon>
      </button>
      <button mat-button routerLink="/logout" matTooltip="Déconnexion" data-test="button-logout">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </div>
</div>