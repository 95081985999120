<div class="upload">
  <input class="file-input" type="file" #inputFile hidden />
  <button mat-flat-button color="primary" (click)="onChooseFile()">Choisir le fichier</button>
  <span class="upload-filename">{{ file ? file.name : '' }}</span>
  <mat-progress-bar
    aria-label="bar de progression de l'import des trajets"
    *ngIf="progress"
    class="upload-progress-bar"
    color="accent"
    mode="buffer"
    [value]="progress"
    [bufferValue]="0"
  >
  </mat-progress-bar>
</div>
