<div class="Footer">
  <div>
    <img src="assets/img/marianne.svg" alt="Logo de la Marianne" />
  </div>
  <div>
    <div class="Footer-title">A propos de nous</div>
    <ul>
      <li class="Footer-link">
        <a class="link" target="_blank" rel="noopener noreferrer" [href]="URLS.betagouv">Qui sommes-nous </a>
      </li>
      <li class="Footer-link">
        <a class="link" target="_blank" rel="noopener noreferrer" [href]="URLS.legalMentions">Mentions légales</a>
      </li>
      <li class="Footer-link">Accessibilité : non conforme</li>
    </ul>
  </div>
  <div>
    <div class="Footer-title">Ressources</div>
    <ul>
      <li class="Footer-link">
        <a class="link" target="_blank" rel="noopener noreferrer" [href]="URLS.mainDoc"> Documentation </a>
      </li>
      <li class="Footer-link">
        <a class="link" target="_blank" rel="noopener noreferrer" [href]="URLS.repository">Code source </a>
      </li>
    </ul>
  </div>
  <div>
    <div class="Footer-title">Contactez-nous</div>
    <ul>
      <li class="Footer-link">
        <a class="link" target="_blank" rel="noopener noreferrer" [href]="'mailto:' + URLS.contactEmail">Par e-mail </a>
      </li>
    </ul>
  </div>
</div>
