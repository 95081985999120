<div class="HelpCard">
  <div class="HelpCard-icon">
    <mat-icon [svgIcon]="svgIcon"></mat-icon>
  </div>
  <div class="HelpCard-title">
    {{ title }}
  </div>
  <p class="HelpCard-hint" [innerHTML]="hint"></p>
  <div class="HelpCard-link" *ngIf="link">
    <a class="link" target="_blank" rel="noopener noreferrer" [href]="link">En savoir plus</a>
  </div>
  <div class="HelpCard-link" *ngIf="button">
    <button mat-flat-button color="accent" (click)="onButtonClick()">
      {{ button }}
    </button>
  </div>
</div>
