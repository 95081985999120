<div class="NotFound">
  <div class="NotFound-error">
    ERREUR
  </div>
  <div class="NotFound-status">
    404
  </div>
  <div class="NotFound-img">
    <img src="assets/img/404.svg" alt="Image d'erreur 404, route non trouvée" />
  </div>
  <div class="NotFound-text">
    route non trouvée
  </div>
</div>
