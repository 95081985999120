<div class="container">
  <div class="error">ERREUR</div>
  <div class="status">503</div>
  <div class="img">
    <img src="assets/img/404.svg" alt="Image d'erreur 503, service indisponible" />
  </div>
  <div class="text">Service indisponible</div>
  <div class="subtext">
    <a href="https://status.covoiturage.beta.gouv.fr">vérifier le statut du service</a>
  </div>
</div>
