<div style="position: relative">
  <mat-form-field
    appearance="outline"
    [class]="{ 'full-width': true, 'mat-form-field-invalid': control?.touched && control?.invalid }"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      type="text"
      [attr.aria-label]="label"
      [placeholder]="placeholder"
      [formControl]="search"
      [matAutocomplete]="auto"
      (focus)="onFocus($event)"
      (blur)="onTouch($event)"
    />
    <mat-icon matSuffix (click)="onIconClick($event)" *ngIf="search.value?.length" class="close-icon">close</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)">
      <mat-option *ngFor="let option of filtered" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- display <mat-error> tags passed by the user -->
  <ng-content></ng-content>
</div>
