<div class="MenuCard">
  <div class="MenuCard-icon">
    <mat-icon [svgIcon]="svgIcon"></mat-icon>
  </div>
  <div class="MenuCard-content">
    <h2 class="MenuCard-title">
      {{ title }}
    </h2>
    <p class="MenuCard-hint">
      {{ hint }}
    </p>
    <div class="MenuCard-link" *ngIf="button">
      <button mat-flat-button color="accent" (click)="onButtonClick()">
        {{ button }}
      </button>
    </div>
  </div>
</div>
